import { useSortable } from '@dnd-kit/sortable';
import { QuestionResponsePrivate } from '@tests/types';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { inc } from 'ramda';
import React, { SetStateAction } from 'react';

import { Delete, Drag } from '@/assets';
import { Visible } from '@/components/Visible';
import { useDisabledByUpdate } from '@/hooks';
import { useDeleteQuestionMutation } from '@/services';

import styles from './styles.module.scss';

type Props = {
  canDelete: boolean;
  index: number;
  onClick: React.Dispatch<SetStateAction<string | number>>;
  question: QuestionResponsePrivate;
  selected: boolean;
};

export const QuestionPreview: React.FC<Props> = ({
  canDelete,
  index,
  onClick,
  question,
  selected,
}) => {
  const { id, media, text } = question;

  const { attributes, listeners } = useSortable({
    id,
  });

  const [deleteQuestion] = useDeleteQuestionMutation();

  const disabled = useDisabledByUpdate();

  const handleDeleteQuestionClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    deleteQuestion(id);
  };

  const handleClick = () => {
    onClick(id);
  };

  return (
    <div
      className={classNames(
        styles.questionPreview,
        selected && styles.selected,
        disabled && styles.disabled,
      )}
      onClick={handleClick}
    >
      <Row align="middle" style={{ height: '100%' }} gutter={[15, 15]}>
        <Col span="2" className={styles.order}>
          {inc(index)}
        </Col>
        <Col span="19">
          <Row gutter={[10, 10]}>
            <Col span={24} className={styles.questionTitlePreview}>
              {text}
            </Col>
            <Col span={24}>
              <div className={styles.questionCoverPreview}>
                <Visible isVisible={!!media?.url}>
                  <img alt="" src={media?.url} />
                </Visible>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span="3" className={styles.buttons}>
          <Row className={styles.buttonsWrapper}>
            <Col className={styles.drag} {...attributes} {...listeners}>
              <Drag />
            </Col>
            <Col
              className={classNames(styles.delete, !canDelete && styles.disabled)}
              onClick={handleDeleteQuestionClick}
            >
              <Delete />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
