import { PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { useCallback } from 'react';

import { useCreateAnswerMutation } from '@/services';

import styles from './styles.module.scss';

type Props = {
  questionId: number;
  sortOrder: number;
};

export const AddAnswerButton: React.FC<Props> = ({ questionId, sortOrder }) => {
  const [createAnswer] = useCreateAnswerMutation();

  const handleAddAnswerClick = useCallback(() => {
    createAnswer({ questionId, sortOrder });
  }, [questionId, sortOrder, createAnswer]);

  return (
    <Row
      justify="center"
      align="middle"
      gutter={[5, 5]}
      className={styles.button}
      onClick={handleAddAnswerClick}
    >
      <Col>
        <PlusOutlined />
      </Col>
      <Col>Добавить Ответ</Col>
    </Row>
  );
};
