import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateTestBodyPrivate,
  GetEmbedQueryPrivate,
  GetEmbedResponsePrivate,
  GetManyStatsResponsePrivate,
  GetManyTestsQueryPrivate,
  GetManyTestsResponsePrivate,
  GetManyTestTypeStatsQueryPrivate,
  GetManyTestTypeStatsResponsePrivate,
  GetTestStatsQueryPrivate,
  TestResponsePrivate,
  TestType,
  UpdateTestBodyPrivate,
} from '@tests/types';

import { ApiPath } from '@/constants';
import { authGuardQuery } from '@/services/api/queries';
import { createUrl } from '@/utils';

export const testsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    createTest: build.mutation<TestResponsePrivate<TestType>, CreateTestBodyPrivate>({
      invalidatesTags: [{ id: 'List', type: 'Tests' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Tests),
      }),
    }),
    deleteTest: build.mutation<void, number | string>({
      invalidatesTags: [{ id: 'List', type: 'Tests' }],
      query: (id) => ({
        method: 'DELETE',
        url: createUrl(ApiPath.Tests, id),
      }),
    }),
    getFiltersTestsList: build.query<
      GetManyTestsResponsePrivate,
      RelationsParams & Partial<GetManyTestsQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((test) => ({ id: test.id, type: 'Tests' as const })) || []),
        { id: 'List', type: 'Tests' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Tests),
      }),
    }),
    getStats: build.query<
      GetManyStatsResponsePrivate<string>,
      { id: number | string } & GetTestStatsQueryPrivate
    >({
      query: ({ id, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Tests, id, 'stats'),
      }),
    }),
    getTestById: build.query<
      TestResponsePrivate<string>,
      RelationsParams & { id: number | string }
    >({
      providesTags: (test) => [{ id: test?.id, type: 'Tests' }],
      query: ({ id, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Tests, id),
      }),
    }),
    getTestEmbed: build.query<
      GetEmbedResponsePrivate,
      GetEmbedQueryPrivate & { id: number | string }
    >({
      query: ({ id, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Tests, id, ApiPath.Embed),
      }),
    }),
    getTestEmbedPreview: build.query<
      GetEmbedResponsePrivate,
      GetEmbedQueryPrivate & { id: number | string }
    >({
      query: ({ id, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Tests, id, ApiPath.EmbedPreview),
      }),
    }),
    getTestsCount: build.query<
      GetManyTestTypeStatsResponsePrivate,
      RelationsParams & Partial<GetManyTestTypeStatsQueryPrivate>
    >({
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Tests, ApiPath.Types),
      }),
    }),
    getTestsList: build.query<
      GetManyTestsResponsePrivate,
      RelationsParams & Partial<GetManyTestsQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((test) => ({ id: test.id, type: 'Tests' as const })) || []),
        { id: 'List', type: 'Tests' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Tests),
      }),
    }),
    publishTest: build.mutation<
      TestResponsePrivate,
      UpdateTestBodyPrivate & { id: number | string }
    >({
      invalidatesTags: (test) => [{ id: test?.id, type: 'Tests' }],
      query: ({ id }) => ({
        method: 'POST',
        url: createUrl(ApiPath.Tests, id, ApiPath.Publish),
      }),
    }),
    unpublishTest: build.mutation<
      TestResponsePrivate,
      UpdateTestBodyPrivate & { id: number | string }
    >({
      invalidatesTags: (test) => [{ idOrAlias: test?.id, type: 'Tests' }],
      query: ({ id }) => ({
        method: 'POST',
        url: createUrl(ApiPath.Tests, id, ApiPath.Unpublish),
      }),
    }),
    updateCrosswordLayout: build.mutation<TestResponsePrivate<TestType>, { id: number | string }>({
      invalidatesTags: (test) => [{ id: test?.id, type: 'Tests' }],
      query: ({ id, ...data }) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Tests, id, ApiPath.Crossword),
      }),
    }),
    updateTest: build.mutation<
      TestResponsePrivate<TestType>,
      UpdateTestBodyPrivate & { id: number | string }
    >({
      invalidatesTags: (test) => [{ id: test?.id, type: 'Tests' }],
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Tests, id),
      }),
    }),
  }),
  reducerPath: 'testsApi',
  tagTypes: ['Tests'],
});

export const {
  useCreateTestMutation,
  useDeleteTestMutation,
  useGetFiltersTestsListQuery,
  useGetStatsQuery,
  useGetTestByIdQuery,
  useGetTestEmbedPreviewQuery,
  useGetTestEmbedQuery,
  useGetTestsCountQuery,
  useGetTestsListQuery,
  usePublishTestMutation,
  useUnpublishTestMutation,
  useUpdateCrosswordLayoutMutation,
  useUpdateTestMutation,
} = testsApi;
