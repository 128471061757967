import { DownOutlined } from '@ant-design/icons';
import { PermissionAction, PermissionResource } from '@tests/types';
import { Avatar, Col, Dropdown, MenuProps, Row, Space } from 'antd';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { AllInteract, Logout, Projects, User, Users } from '@/assets';
import { usePermissions } from '@/hooks';
import { useGetCurrentUserQuery, useLogoutUserMutation } from '@/services';

import styles from './styles.module.scss';

export const UserDropdown = () => {
  const { data: user } = useGetCurrentUserQuery();
  const [logoutUser] = useLogoutUserMutation();
  const avatar = user?.avatar?.url;

  const checkPermissions = usePermissions;

  const generalItems: MenuProps['items'] = [
    {
      type: 'divider',
    },
    {
      key: 'Logout',
      label: (
        <Space align="center" onClick={() => logoutUser()}>
          <Row>
            <Logout />
          </Row>
          <Row>Выйти из аккаунта</Row>
        </Space>
      ),
    },
  ];

  const customizableItems: MenuProps['items'] = [
    {
      key: 'Test',
      label: (
        <Link to="/tests">
          <Space align="center">
            <Row>
              <AllInteract />
            </Row>
            <Row>Все интерактивы</Row>
          </Space>
        </Link>
      ),
    },
    {
      key: 'Project',
      label: (
        <Link to="/projects">
          <Space align="center">
            <Row>
              <Projects />
            </Row>
            <Row>Проекты</Row>
          </Space>
        </Link>
      ),
    },
    {
      key: 'Employee',
      label: (
        <Link to="/employees">
          <Space align="center">
            <Row>
              <Users />
            </Row>
            <Row>Сотрудники</Row>
          </Space>
        </Link>
      ),
    },
  ];

  const permittedItems: MenuProps['items'] = customizableItems.filter((item) =>
    checkPermissions(item.key as PermissionResource, PermissionAction.Read),
  );

  return (
    <Dropdown
      rootClassName={styles.dropDownMenu}
      menu={{ items: [...permittedItems, ...generalItems] }}
      trigger={['click']}
      destroyPopupOnHide
    >
      <div className={styles.dropdownContent}>
        <Avatar src={avatar} className={classNames(styles.avatarWrapper, !avatar && styles.avatar)}>
          {!avatar && <User />}
        </Avatar>
        <Row className={styles.name}>
          <Col span={24}>{user?.name}</Col>
        </Row>
        <DownOutlined className={styles.arrow} />
      </div>
    </Dropdown>
  );
};
