import { Permission, PermissionAction, PermissionResource } from '@tests/types';
import { useSelector } from 'react-redux';

import { userRoleSelector } from '@/selectors';

const groupPermissionsByResource = (list: Permission[]) =>
  list?.reduce(
    (hash: Record<string, string[]>, obj: Permission) => ({
      ...hash,
      [obj.resource]: (hash[obj.resource] || []).concat(obj.action),
    }),
    {},
  );

export const usePermissions = (resource: PermissionResource, action: PermissionAction) => {
  const role = useSelector(userRoleSelector);

  return (
    groupPermissionsByResource(role?.permissions) &&
    groupPermissionsByResource(role?.permissions)[resource]?.includes(action)
  );
};
