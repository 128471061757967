import { FileStatus } from '@tests/types';
import { Button, Modal } from 'antd';
import classNames from 'classnames';
import { inc } from 'ramda';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { backgroundsEntitySelector } from '@/selectors';

import { useGetBackgroundsQuery } from '../../services';
import { isDefined } from '../../utils';
import { InfiniteScroll } from '../InfiniteScroll';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  onSetImage: (id: number) => void;
  testId: number;
}

export const InteractiveImagesModal: FC<Props> = ({ onClose, onSetImage, testId }) => {
  const [page, setPage] = useState(0);

  const { data, isFetching } = useGetBackgroundsQuery(
    { isModal: true, offset: page * 30, order: '-id', tests: [testId] },
    { refetchOnMountOrArgChange: true, skip: !testId },
  );

  const images = useSelector(backgroundsEntitySelector.selectAll);

  const filteredImages = useMemo(
    () => images.filter((image) => image.status === FileStatus.Complete),
    [images],
  );
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchData = async () => {
    setPage(inc);
  };

  useEffect(() => {
    document.body.style.width = '100%';

    return () => {
      document.body.style.width = '';
    };
  }, []);

  return (
    <Modal
      title={<span className={styles.modalTitle}>Ранее загруженные</span>}
      open
      onClose={onClose}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      className={styles.modal}
    >
      <div className={styles.body}>
        <InfiniteScroll
          hasMore={isDefined(data) && data?.count > images?.length}
          isFetching={isFetching}
          fetchData={fetchData}
        >
          <div className={styles.grid}>
            {filteredImages.map((image) => (
              <div
                key={image.id}
                className={classNames(styles.image, selectedImage === image.id && styles.selected)}
                onClick={() => {
                  if (selectedImage === image.id) {
                    setSelectedImage(null);
                  } else {
                    setSelectedImage(image.id);
                  }
                }}
              >
                <img src={image.url} alt={image.name} />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
      <div className={styles.buttons}>
        <Button
          disabled={!selectedImage}
          type="primary"
          onClick={() => onSetImage(selectedImage)}
          className={styles.button}
        >
          Добавить
        </Button>
      </div>
    </Modal>
  );
};
