import { createApi } from '@reduxjs/toolkit/query/react';
import {
  EmployeeResponsePrivate,
  GetManyEmployeesQueryPrivate,
  GetManyEmployeesResponsePrivate,
  UpdateEmployeeBodyPrivate,
} from '@tests/types';

import { ApiPath } from '@/constants';
import { createUrl } from '@/utils';

import { authGuardQuery } from './queries';

export const employeesApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getEmployeesList: build.query<
      GetManyEmployeesResponsePrivate,
      RelationsParams & Partial<GetManyEmployeesQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((employee) => ({
          id: employee.id,
          type: 'Employees' as const,
        })) || []),
        { id: 'List', type: 'Employees' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Employees),
      }),
    }),
    updateEmployee: build.mutation<
      EmployeeResponsePrivate,
      UpdateEmployeeBodyPrivate & { id: number }
    >({
      invalidatesTags: (result) => [{ id: result?.id, type: 'Employees' }],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(employeesApi.util.invalidateTags(['Employees']));
        });
      },
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Employees, id),
      }),
    }),
  }),
  reducerPath: 'employeesApi',
  tagTypes: ['Employees'],
});

export const { useGetEmployeesListQuery, useUpdateEmployeeMutation } = employeesApi;
