import { Button, Col, Row, Space } from 'antd';
import type { FC } from 'react';

import { useSSOProvider } from '@/hooks';

import styles from './styles.scss';

export const LoginPage: FC = () => {
  const ssoProvider = useSSOProvider();

  return (
    <Row className={styles.wrapper}>
      <Col>
        <Space direction="vertical" align="center">
          <Button href={ssoProvider?.getAuthUrl()}>SSO</Button>
        </Space>
      </Col>
    </Row>
  );
};
