import {
  EmployeeResponsePrivate,
  GetManyTestsQueryPrivate,
  PermissionAction,
  PermissionResource,
  TestType,
} from '@tests/types';
import { Col, FloatButton, Row, Space, Tabs, TabsProps } from 'antd';
import classNames from 'classnames';
import { inc, isEmpty } from 'ramda';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ArrowTop, Filters } from '@/assets';
import { Sort, SortField } from '@/components';
import { SortDirections } from '@/constants';
import { FiltersComponent, TestsList, TestsListHeader } from '@/containers';
import { usePermissions } from '@/hooks';
import { employeesEntitySelector } from '@/selectors';
import { useGetEmployeesListQuery, useGetTestsCountQuery } from '@/services';
import { useGetProjectsListQuery } from '@/services/api/projects';
import { getTestTypeCount, sortToString } from '@/utils';

import styles from './styles.module.scss';

const sortFields: SortField[] = [
  { label: 'По дате создания', value: 'createdAt' },
  { label: 'По дате публикации', value: 'publishedAt' },
];

export const TestsPage: React.FC = () => {
  const [order, setOrder] = useState<Record<string, SortDirections>>({
    createdAt: SortDirections.Desc,
  });

  const navigate = useNavigate();
  const [URLSearchParams] = useSearchParams();
  const paramsTab = URLSearchParams.get('tab');

  const [searchTerm, setSearchTerm] = useState<string>();
  const [showFilter, setShowFilter] = useState<boolean>();
  const [employeesPage, setEmployeesPage] = useState(0);
  const [filtersParams, setFiltersParams] = useState<Partial<GetManyTestsQueryPrivate>>({});

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const getTestsCountParams = useMemo(() => {
    let params = {} as Partial<GetManyTestsQueryPrivate>;
    if (searchTerm) {
      params.title = searchTerm;
    }
    if (!isEmpty(filtersParams)) {
      params = {
        ...params,
        ...filtersParams,
      };
    }

    return params;
  }, [searchTerm, filtersParams]);

  const { data: { results: testsCount = [] } = {} } = useGetTestsCountQuery(getTestsCountParams, {
    refetchOnMountOrArgChange: true,
  });

  const { data: employeesData, isFetching: isEmployeesFetching } = useGetEmployeesListQuery(
    {
      offset: employeesPage * 30,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Employee, PermissionAction.Read),
    },
  );

  useGetProjectsListQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Project, PermissionAction.Read),
    },
  );

  const employees: EmployeeResponsePrivate<string>[] =
    useSelector(employeesEntitySelector.selectAll) || [];

  const handleFetchEmployees = async () => {
    setEmployeesPage(inc);
  };

  const handleTabChange = (key: string) => {
    navigate(`?tab=${key}`, { replace: false });
  };

  const items: TabsProps['items'] = [
    {
      children: (
        <TestsList
          type={null}
          filtersParams={filtersParams}
          order={sortToString(order)}
          searchTerm={searchTerm}
        />
      ),
      key: 'all',
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Все</Col>
          <Col className={styles.tabBadge}>{getTestTypeCount(testsCount, 'all')}</Col>
        </Row>
      ),
    },
    {
      children: (
        <TestsList
          type={TestType.Test}
          order={sortToString(order)}
          filtersParams={filtersParams}
          searchTerm={searchTerm}
        />
      ),
      key: TestType.Test,
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Тесты</Col>
          <Col className={styles.tabBadge}>{getTestTypeCount(testsCount, TestType.Test)}</Col>
        </Row>
      ),
    },
    {
      children: (
        <TestsList
          type={TestType.Extended}
          order={sortToString(order)}
          filtersParams={filtersParams}
          searchTerm={searchTerm}
        />
      ),
      key: TestType.Extended,
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Расширенные тесты</Col>
          <Col className={styles.tabBadge}>{getTestTypeCount(testsCount, TestType.Extended)}</Col>
        </Row>
      ),
    },
    {
      children: (
        <TestsList
          type={TestType.Quiz}
          order={sortToString(order)}
          filtersParams={filtersParams}
          searchTerm={searchTerm}
        />
      ),
      key: TestType.Quiz,
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Квизы</Col>
          <Col className={styles.tabBadge}>{getTestTypeCount(testsCount, TestType.Quiz)}</Col>
        </Row>
      ),
    },
    {
      children: (
        <TestsList
          type={TestType.Poll}
          order={sortToString(order)}
          filtersParams={filtersParams}
          searchTerm={searchTerm}
        />
      ),
      key: TestType.Poll,
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Голосования</Col>
          <Col className={styles.tabBadge}>{getTestTypeCount(testsCount, TestType.Poll)}</Col>
        </Row>
      ),
    },
    {
      children: (
        <TestsList
          type={TestType.Crossword}
          order={sortToString(order)}
          filtersParams={filtersParams}
          searchTerm={searchTerm}
        />
      ),
      key: TestType.Crossword,
      label: (
        <Row className={styles.tabWrapper}>
          <Col>Кроссворды</Col>
          <Col className={styles.tabBadge}>{getTestTypeCount(testsCount, TestType.Crossword)}</Col>
        </Row>
      ),
    },
  ];

  const validKeys = items.map((item) => item.key);
  const defaultTab = validKeys.includes(paramsTab) ? paramsTab : 'all';

  const additionalTabButtons = (
    <Space
      className={classNames(styles.showFilterBtn, showFilter && styles.activeShowFilterBtn)}
      onClick={() => setShowFilter(!showFilter)}
    >
      <Row align="middle">
        <Filters />
      </Row>
      <Row align="middle">
        {showFilter ? <Col span={24}>Скрыть фильтры</Col> : <Col span={24}>Все фильтры</Col>}
      </Row>
    </Space>
  );

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <>
      <DefaultTabBar {...props} className={styles.tabBar} />
      <div className={styles.mobileFilterButton}>{additionalTabButtons}</div>
      {showFilter && (
        <FiltersComponent
          employeesList={employees.map(({ id, name }) => ({ label: name, value: id }))}
          updateFilters={setFiltersParams}
          hasMoreEmployees={employeesData?.count > employees?.length}
          onFetchEmployees={handleFetchEmployees}
          loadingEmployees={isEmployeesFetching}
        />
      )}
      <div style={{ marginBottom: '15px' }}>
        <Sort fields={sortFields} value={order} onChange={setOrder} />
      </div>
    </>
  );

  return (
    <div className={styles.content}>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <TestsListHeader onSearch={handleSearch} />
        </Col>
      </Row>
      <Tabs
        className={styles.tabs}
        destroyInactiveTabPane
        tabBarExtraContent={additionalTabButtons}
        tabBarGutter={20}
        defaultActiveKey={defaultTab}
        onTabClick={handleTabChange}
        renderTabBar={renderTabBar}
        items={items}
      />

      <FloatButton.BackTop className={styles.scrollButton} icon={<ArrowTop />} />
    </div>
  );
};
