import { PlusOutlined } from '@ant-design/icons';
import { AnswerType, TestResponsePrivate } from '@tests/types';
import { Button } from 'antd';
import React, { SetStateAction, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { CrosswordQuestionBlock } from '@/containers/Blocks/CrosswordQuestionBlock';
import { useDisabledByUpdate } from '@/hooks';
import { countQuestionsSelector, questionsItemsSelector } from '@/selectors';
import { useCreateQuestionMutation, useGetQuestionsListQuery } from '@/services';

import styles from './styles.module.scss';

type Props = {
  test: TestResponsePrivate;
};

export const QuestionsList: React.FC<Props> = ({ test }) => {
  const questionsCount = useSelector(countQuestionsSelector);
  const questions = useSelector(questionsItemsSelector);

  const { id } = test;
  const disabled = useDisabledByUpdate();

  const questionsLimit = 15;

  const [createQuestion] = useCreateQuestionMutation();

  const handleAddQuestion = useCallback(() => {
    createQuestion({ answerType: AnswerType.Text, sortOrder: questionsCount, testId: id });
  }, [createQuestion, id, questionsCount]);

  useGetQuestionsListQuery(
    { limit: 100, offset: 0, relations: ['media'], test: id },
    {
      refetchOnMountOrArgChange: true,
      skip: !id,
    },
  );

  return (
    <div className={styles.questionsListWrapper}>
      {questions?.map((question, index) => (
        <div className={styles.question}>
          <CrosswordQuestionBlock
            id={question.id}
            questionIndex={index + 1}
            text={question.text}
            questionsNumber={questions.length}
            canDelete={questions.length > 2}
          />
        </div>
      ))}
      {questions?.length < questionsLimit && (
        <div className={styles.addQuestionButtonWrapper}>
          <Button
            disabled={disabled}
            className={styles.addQuestionButton}
            onClick={handleAddQuestion}
          >
            <PlusOutlined /> Добавить вопрос
          </Button>
        </div>
      )}
    </div>
  );
};
