import { Col, Form, FormInstance, Row } from 'antd';
import { equals, pick } from 'ramda';
import { FC, PropsWithChildren, useCallback, useState } from 'react';

import { useDebounce } from '@/hooks';

import { BlockWrapper } from '../BlockWrapper';

export interface FormBlockProps {
  blockTitle?: string;
  count?: number;
  form?: FormInstance<any>;
  initialValues?: any;
  inverted?: boolean;
  maxCount?: number;
  onValuesChange?(data: any): void;
  testId?: number;
  updateBlock: any;
}

export const FormBlock: FC<PropsWithChildren<FormBlockProps>> = (props) => {
  const { children, form, initialValues, onValuesChange, testId, updateBlock } = props;

  const { getFieldsValue, setFields, submit } = form;

  const [previousFormValues, setPreviousFormValues] = useState(initialValues || {});

  const handleFormChangeHandler = useDebounce(() => {
    submit();
  }, 300);

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();

    await updateBlock({
      ...formValues,
      id: testId,
    });
  }, [getFieldsValue, testId, updateBlock]);

  const handleValuesChangeHandler: FormBlockProps['onValuesChange'] = useCallback(
    (changedValues: any) => {
      const formValuesToCompare = pick(Object.keys(changedValues))(previousFormValues);

      if (equals(changedValues, formValuesToCompare)) {
        return;
      }

      setFields(Object.keys(changedValues).map((name) => ({ errors: [], name })));

      setPreviousFormValues({ ...previousFormValues, ...changedValues });
      // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
      onValuesChange && onValuesChange(changedValues);
      handleFormChangeHandler.current();
    },
    [handleFormChangeHandler, onValuesChange, previousFormValues, setFields],
  );

  return (
    <BlockWrapper {...props}>
      <Form
        name="block"
        form={form}
        initialValues={initialValues}
        layout="vertical"
        validateTrigger="onKeyPress"
        onValuesChange={handleValuesChangeHandler}
        onFinish={handleFinish}
      >
        <Row gutter={[18, 20]}>
          <Col span={24}>{children}</Col>
        </Row>
      </Form>
    </BlockWrapper>
  );
};
