import { FileStatus } from '@tests/types';
import classNames from 'classnames';
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useWindowDimensions } from '../../hooks';
import { backgroundsEntitySelector } from '../../selectors';
import { InteractiveImagesModal } from '../InteractiveImagesModal';
import styles from './styles.module.scss';

interface Props {
  onSelectImage: (id: number) => void;
  selectedImageId?: number;
  testId?: number;
}

export const InteractiveImages: FC<Props> = ({ onSelectImage, selectedImageId, testId }) => {
  const images = useSelector(backgroundsEntitySelector.selectAll);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const windowWidth = useWindowDimensions().width;

  const isShowMoreButton = useMemo(() => {
    if (windowWidth > 800) {
      return images.length > 6;
    }

    return images.length > 4;
  }, [windowWidth, images]);

  const handleCloseModal = () => setIsOpenModal(false);

  const handleOpenModal = () => setIsOpenModal(true);

  const handleSetImage = (imageId: number) => {
    if (selectedImageId !== imageId) {
      onSelectImage(imageId);
      handleCloseModal();
    }
  };

  if (images.length === 0) return null;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Ранее загруженные</div>
          {isShowMoreButton && (
            <div className={styles.button} onClick={handleOpenModal}>
              Посмотреть все
            </div>
          )}
        </div>
        <div className={styles.images}>
          {images.slice(0, 6).map((image) => (
            <div
              className={classNames(styles.image, selectedImageId === image.id && styles.selected)}
              key={image.id}
              onClick={() => {
                if (selectedImageId !== image.id) {
                  onSelectImage(image.id);
                }
              }}
            >
              <img src={image.url} alt={image.name} />
            </div>
          ))}
        </div>
      </div>
      {isOpenModal && (
        <InteractiveImagesModal
          onClose={handleCloseModal}
          onSetImage={handleSetImage}
          testId={testId}
        />
      )}
    </>
  );
};
