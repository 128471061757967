import { GetManyTestsQueryPrivate } from '@tests/types';
import { Button, Col, Form, Row } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'ramda';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { DateFilter, ProjectsFilter, SelectRender } from '@/components';
import { useGetTestsListQuery } from '@/services';

import styles from './styles.module.scss';

type FiltersItem = {
  label: string;
  value: number;
};

type FiltersProps = {
  employeesList: FiltersItem[];
  hasMoreEmployees: boolean;
  loadingEmployees: boolean;
  onFetchEmployees: () => Promise<void>;
  updateFilters: (params: Partial<GetManyTestsQueryPrivate<string>>) => void;
};

export const FiltersComponent: FC<FiltersProps> = ({
  employeesList,
  hasMoreEmployees,
  loadingEmployees,
  onFetchEmployees,
  updateFilters,
}) => {
  const [checkedProjects, setCheckedProjects] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [selectedPublishedDate, setSelectedPublishedDate] = useState(null);
  const [selectedCreatedDate, setSelectedCreatedDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [form] = Form.useForm();

  const getListQueryParams = useMemo(() => {
    const params = {} as Partial<GetManyTestsQueryPrivate<string>>;
    if (selectedPublishedDate) {
      params.publishedAt = selectedPublishedDate;
    }
    if (selectedCreatedDate) {
      params.createdAt = selectedCreatedDate;
    }
    if (checkedProjects.length) {
      params.projects = checkedProjects.map(({ value }) => value);
    }
    if (employee) {
      params.createdBy = employee;
    }
    if (status) {
      params.status = status;
    }

    return params;
  }, [selectedCreatedDate, checkedProjects, employee, selectedPublishedDate, status]);

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const handleEmployeeChange = (value: string) => {
    if (value) {
      return setEmployee(+value);
    }

    return setEmployee(null);
  };

  const { isFetching } = useGetTestsListQuery(getListQueryParams, {
    skip: true,
  });

  const onFinish = useCallback(() => {
    updateFilters(getListQueryParams);
  }, [updateFilters, getListQueryParams]);

  const handleReset = useCallback(() => {
    setCheckedProjects([]);
    setEmployee(null);
    setSelectedCreatedDate(null);
    setSelectedPublishedDate(null);
    setStatus(null);
    form.resetFields();
    updateFilters({});
  }, [updateFilters, form]);

  useEffect(() => {
    if (isEmpty(getListQueryParams)) {
      updateFilters({});
    }
  }, [getListQueryParams, updateFilters]);

  const statusOptions = [
    { label: 'Черновик', value: 'draft' },
    { label: 'Опубликована', value: 'published' },
    { label: 'Снята с публикации', value: 'unpublished' },
  ];

  return (
    <Form form={form} onFinish={onFinish}>
      <Row className={styles.filters}>
        <Row className={styles.selectors} gutter={[20, 20]}>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Проект</div>
                <ProjectsFilter
                  checkedProjects={checkedProjects}
                  setCheckedProjects={setCheckedProjects}
                />
              </Col>
            </Row>
          </Col>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Сотрудник</div>
                <SelectRender
                  value={employee}
                  options={employeesList}
                  onChange={handleEmployeeChange}
                  hasMore={hasMoreEmployees}
                  fetchData={onFetchEmployees}
                  loading={loadingEmployees}
                />
              </Col>
            </Row>
          </Col>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Статус</div>
                <SelectRender
                  value={status}
                  onChange={handleStatusChange}
                  options={statusOptions}
                />
              </Col>
            </Row>
          </Col>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Период создания</div>
                <DateFilter
                  selectedDate={selectedCreatedDate}
                  setSelectedDate={setSelectedCreatedDate}
                  name="createdAt"
                />
              </Col>
            </Row>
          </Col>
          <Col className={styles.selectorWrapper}>
            <Row>
              <Col>
                <div className={styles.selectorLabel}>Период публикации</div>
                <DateFilter
                  selectedDate={selectedPublishedDate}
                  setSelectedDate={setSelectedPublishedDate}
                  name="publishedAt"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[15, 0]} className={styles.filtersBtnWrapper} justify="end">
          <Col className={styles.filtersBtnCol}>
            <Button
              className={classNames(styles.filtersBtn, styles.resetFilters)}
              onClick={handleReset}
              disabled={isEmpty(getListQueryParams)}
            >
              Сбросить
            </Button>
          </Col>
          <Col className={styles.filtersBtnCol}>
            <Form.Item>
              <Button
                disabled={isFetching || isEmpty(getListQueryParams)}
                htmlType="submit"
                className={classNames(styles.filtersBtn, styles.filtersSubmitBtn)}
                type="primary"
              >
                Применить
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </Form>
  );
};
