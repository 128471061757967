import createSagaMiddleware from 'redux-saga';

import {
  answersApi,
  configApi,
  projectsApi,
  questionsApi,
  resultsApi,
  testsApi,
  themesApi,
} from '@/services';

export const sagaMiddleware = createSagaMiddleware();

export const middlewares = [
  sagaMiddleware,
  ...[configApi, testsApi, resultsApi, questionsApi, answersApi, projectsApi, themesApi].map(
    (api) => api.middleware,
  ),
];
