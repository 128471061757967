import { PlusOutlined } from '@ant-design/icons';
import { AnswerType, TestResponsePrivate } from '@tests/types';
import { Button } from 'antd';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useDisabledByUpdate } from '@/hooks';
import { countQuestionsSelector, questionsItemsSelector } from '@/selectors';
import { useCreateQuestionMutation } from '@/services';

import styles from './styles.module.scss';

type Props = {
  test: TestResponsePrivate;
};

export const AddQuestionButton: React.FC<Props> = ({ test }) => {
  const questions = useSelector(questionsItemsSelector);
  const questionsCount = useSelector(countQuestionsSelector);
  const disabled = useDisabledByUpdate();
  const [createQuestion] = useCreateQuestionMutation();

  const { id } = test;

  const handleAddQuestion = useCallback(() => {
    createQuestion({ answerType: AnswerType.Text, sortOrder: questionsCount, testId: id });
  }, [createQuestion, id, questionsCount]);

  const questionsLimit = 15;

  return (
    <>
      {questions.length < questionsLimit && (
        <div className={styles.addQuestionButtonWrapper}>
          <Button
            disabled={disabled}
            className={styles.addQuestionButton}
            onClick={handleAddQuestion}
          >
            <PlusOutlined /> Добавить вопрос
          </Button>
        </div>
      )}
    </>
  );
};
