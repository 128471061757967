import { Col, Row } from 'antd';
import { always, cond, equals, T } from 'ramda';
import React from 'react';

import { formatDate } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  date: string;
  nowrap?: boolean;
  type: 'created' | 'updated' | 'saved';
};

const getDateChangedTypeTitle = cond<[string], Nullable<string>>([
  [equals('created'), always('Создан')],
  [equals('updated'), always('Изменен')],
  [equals('saved'), always('Сохранен')],
  [T, always(null)],
]);

export const DateChanged: React.FC<Props> = ({ date, nowrap = false, type }) => (
  <Row justify="space-between" wrap={!nowrap}>
    <Col className={styles.title}>{getDateChangedTypeTitle(type)}:&nbsp;</Col>
    <Col className={styles.date}>{formatDate(date, {})}</Col>
  </Row>
);
