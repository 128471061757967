import { createSlice } from '@reduxjs/toolkit';

import { backgroundsAdapter } from '@/adapters';

import { filesApi, testsApi } from '../services';

interface State {
  testId: number | null;
}
export const backgroundsSlice = createSlice({
  extraReducers: (build) => {
    build
      .addMatcher(testsApi.endpoints.getTestById.matchFulfilled, (state, action) => {
        if (state.testId !== +action.meta.arg.originalArgs.id) {
          backgroundsAdapter.removeAll(state);
          state.testId = +action.meta.arg.originalArgs.id;
        }
      })
      .addMatcher(filesApi.endpoints.getBackgrounds.matchPending, (state, action) => {
        if (
          !action.meta.arg?.originalArgs?.isModal &&
          action.meta.arg?.originalArgs?.offset === 0 &&
          action.meta.arg.subscribe
        ) {
          backgroundsAdapter.removeAll(state);
        }
      })
      .addMatcher(filesApi.endpoints.getBackgrounds.matchFulfilled, (state, action) => {
        backgroundsAdapter.upsertMany(state, action.payload.results);
      })
      .addMatcher(filesApi.endpoints.getFileById.matchFulfilled, (state, action) => {
        backgroundsAdapter.upsertOne(state, action.payload);
      });
  },
  initialState: backgroundsAdapter.getInitialState<State>({
    testId: null,
  }),
  name: 'backgrounds',
  reducers: {},
});

export const backgroundsReducer = backgroundsSlice.reducer;
