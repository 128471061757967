import { FileStatus } from '@tests/types';
import { always, cond, equals, lensIndex, path, pipe, prop, propEq, view } from 'ramda';
import { Accept } from 'react-dropzone';

import { DropType } from '@/constants';

export const getAccept = cond<[DropType], Accept>([
  [equals(DropType.Image), always({ 'image/jpeg': [], 'image/png': [] })],
  [equals(DropType.Gif), always({ 'image/gif': [] })],
  [equals(DropType.Video), always({ 'video/mp4': [] })],
  [
    equals(DropType.All),
    always({
      'image/gif': [],
      'image/jpeg': [],
      'image/png': [],
      // раскомментить для видео
      // 'video/mp4': [],
    }),
  ],
]);

export const isImage = equals(DropType.Image);

export const isError = propEq(FileStatus.Error, 'status');

export const isComplete = propEq(FileStatus.Complete, 'status');

export const isInProgress = propEq(FileStatus.InProgress, 'status');

export const getErrorMessage = pipe(prop('errors'), view(lensIndex(0)), prop('message'));

export const getFileName = path<string>(['file', 'name']);
