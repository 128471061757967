import { Col, Form, InputNumber, Row, Slider } from 'antd';
import React, { useCallback, useState } from 'react';

import { isDefined } from '@/utils';

import { FormBlock } from '../Wrappers/FormBlock';
import styles from './styles.module.scss';

type Props = {
  blockTitle: string;
  formItemName?: string;
  id: number;
  max: number;
  min: number;
  updateBlock: any;
  value: number;
};

export const SliderBlock: React.FC<Props> = ({
  blockTitle,
  formItemName = '',
  id,
  max = 100,
  min = 0,
  updateBlock,
  value = 0,
}) => {
  const [form] = Form.useForm();
  const { setFieldValue } = form;
  const [timeout, setTimeout] = useState(value);

  const onValuesChange = useCallback(
    ({ timeout: changedTimeout }: { timeout: number }) => {
      if (!isDefined(changedTimeout)) {
        setFieldValue('timeout', 0);
        setTimeout(0);
      }
    },
    [setFieldValue],
  );

  return (
    <FormBlock
      blockTitle={blockTitle}
      testId={id}
      form={form}
      initialValues={{ [formItemName]: timeout }}
      updateBlock={updateBlock}
      onValuesChange={onValuesChange}
    >
      <Row align="middle" gutter={[20, 10]} className={styles.wrapper}>
        <Col>
          <Form.Item name={formItemName}>
            <Slider
              min={min}
              max={max}
              value={typeof timeout === 'number' ? timeout : 0}
              className={styles.slider}
            />
          </Form.Item>
        </Col>
        <Col>
          <Row align="middle" gutter={[12, 12]}>
            <Col>
              <Form.Item name={formItemName}>
                <InputNumber
                  min={min}
                  max={max}
                  value={typeof timeout === 'number' ? timeout : 0}
                  className={styles.input}
                />
              </Form.Item>
            </Col>
            <Col>секунд</Col>
          </Row>
        </Col>
      </Row>
    </FormBlock>
  );
};
