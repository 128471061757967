import { Col, Form, Row, Switch } from 'antd';
import React from 'react';

import { FormBlock } from '../Wrappers/FormBlock';

type Props = {
  checked: boolean;
  formItemName?: string;
  id: number;
  infoText?: string;
  updateBlock: any;
};

export const SwitcherBlock: React.FC<Props> = ({
  checked,
  formItemName = '',
  id,
  infoText,
  updateBlock,
}) => {
  const [form] = Form.useForm();

  return (
    <FormBlock
      testId={id}
      form={form}
      initialValues={{ [formItemName]: checked }}
      updateBlock={updateBlock}
      inverted
    >
      <Row align="middle" gutter={[10, 10]}>
        <Col>
          <Form.Item name={formItemName}>
            <Switch checked={checked} />
          </Form.Item>
        </Col>
        <Col>{infoText}</Col>
      </Row>
    </FormBlock>
  );
};
