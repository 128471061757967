import { LeftOutlined } from '@ant-design/icons';
import { TestResponsePrivate } from '@tests/types';
import { Col, Row } from 'antd';
import { always, cond, equals, T } from 'ramda';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Badge, DateChanged, Status } from '@/components';

import styles from './styles.module.scss';

type Props = {
  test: TestResponsePrivate;
};

const getTypeTitle = cond<[string], Nullable<string>>([
  [equals('test'), always('Тест')],
  [equals('extended'), always('Расширенный тест')],
  [equals('quiz'), always('Квиз')],
  [equals('poll'), always('Голосование')],
  [equals('crossword'), always('Кроссворд')],
  [T, always(null)],
]);

export const TestStatsPageHeader: React.FC<Props> = ({ test }) => {
  const { createdAt, status, title, type, updatedAt } = test;

  const navigate = useNavigate();

  const handleGoBackButton = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      navigate('/tests');
    }
  };

  return (
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
      <Col span={24}>
        <Row gutter={[16, 16]} align="middle">
          <Col className={styles.goBackButton} onClick={handleGoBackButton}>
            <LeftOutlined /> Назад
          </Col>
          <Col>
            <Row align="middle" gutter={[7, 7]}>
              <Col>
                <Badge className={type}>{getTypeTitle(type)}</Badge>
              </Col>
              <Col>
                <Status status={status} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row gutter={[10, 10]} align="middle">
              <Col className={styles.dates}>
                <DateChanged date={createdAt} type="created" />
              </Col>
              <Col>
                <div className={styles.separator} />
              </Col>
              <Col className={styles.dates}>
                <DateChanged date={updatedAt} type="updated" />
              </Col>
              <Col className={styles.title}>{title}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
