import { TestResponsePrivate, TestType } from '@tests/types';
import { always, cond, equals } from 'ramda';
import React from 'react';

import { useUpdateTestMutation } from '@/services';
import { isExtendedTest, isPoll, isQuiz, isTest } from '@/utils';

import { SubtitleBlock, SwitcherBlock, TestCoverBlock, TitleBlock } from '../Blocks';
import styles from './styles.module.scss';

type Props = {
  test: TestResponsePrivate;
};

const getInfoText = cond([
  [equals(TestType.Quiz), always('Отображать баллы (для всех диапазонов)')],
  [equals(TestType.Extended), always('Отображать баллы (для всех результатов)')],
  [equals(TestType.Test), always('Отображать баллы (для всех диапазонов)')],
]);

export const GeneralSettings: React.FC<Props> = ({ test }) => {
  const {
    backgroundImage,
    backgroundImageId,
    backgroundPlace,
    crosswordBackgroundImage,
    crosswordBackgroundImageId,
    hideResultPercents,
    id,
    showPointsForAllRanges,
    subtitle,
    title,
    type,
  } = test;

  const [updateBlock] = useUpdateTestMutation();

  return (
    <div className={styles.container}>
      {(isQuiz(type) || isTest(type) || isExtendedTest(type)) && (
        <SwitcherBlock
          checked={showPointsForAllRanges}
          formItemName="showPointsForAllRanges"
          infoText={getInfoText(type)}
          id={id}
          updateBlock={updateBlock}
        />
      )}
      {isPoll(type) && (
        <SwitcherBlock
          checked={hideResultPercents}
          formItemName="hideResultPercents"
          infoText="Скрывать процент выбравших ответ (для всех вопросов)"
          id={id}
          updateBlock={updateBlock}
        />
      )}
      <TitleBlock text={title} id={id} updateBlock={updateBlock} />
      <SubtitleBlock text={subtitle} id={id} captionMaxLength={270} updateBlock={updateBlock} />
      <TestCoverBlock
        id={id}
        updateBlock={updateBlock}
        backgroundImageId={backgroundImageId}
        backgroundImage={backgroundImage}
        crosswordBackgroundImageId={crosswordBackgroundImageId}
        crosswordBackgroundImage={crosswordBackgroundImage}
        backgroundPlace={backgroundPlace}
        testType={test.type}
      />
    </div>
  );
};
