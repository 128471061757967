import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { SetStateAction, useEffect, useState } from 'react';

import { useDisabledByUpdate } from '@/hooks';

import styles from './styles.module.scss';

type Props = {
  activeMenu: string | number;
  items: { key: string; label: string }[];
  questionsCount: number;
  setActiveEditContent: React.Dispatch<SetStateAction<string | number>>;
};

export const LeftSideMenu: React.FC<Props> = ({
  items = [],
  setActiveEditContent,
  activeMenu,
  questionsCount,
}) => {
  const [selectedItem, setSelectedItem] = useState(activeMenu || null);

  const disabled = useDisabledByUpdate();

  useEffect(() => {
    setSelectedItem(activeMenu);
    const testNumber = window.location.href.split('/').pop();
    const activeTestMenu: Record<string, string> = {};
    activeTestMenu[testNumber] = String(activeMenu);
    sessionStorage.setItem('activeEditContent', JSON.stringify(activeTestMenu));
  }, [activeMenu]);

  if (!items) {
    return <div>Ошибка загрузки пунктов меню!</div>;
  }

  return (
    <Row>
      {items.map(({ key, label }) => (
        <Col
          onClick={() => {
            setSelectedItem(key);
            setActiveEditContent(key);
          }}
          span={24}
          className={classNames(
            styles.item,
            selectedItem === key && styles.selected,
            disabled && styles.disabled,
          )}
        >
          {label}
          {key === 'questions' && <span className={styles.questionsCount}>{questionsCount}</span>}
        </Col>
      ))}
    </Row>
  );
};
